<template>
    <div class="shipping-data shipping-data--address">


        <div class="uk-animation-fade " v-show="mode == 'select'">
            <div class="uk-flex uk-flex-bottom">
                <form-input type="select"
                            label="Your Shipping Address"
                            :auto-translate="false"
                            :null-option="{value: null, disabled: true, label: 'Select one of your addresses or add a new one'}"
                            v-model="value"
                            :nullOptionSelectable="false"
                            class="uk-margin-remove"
                            :list="addressList"></form-input>
                <form-button type="primary"
                             @click="addressSelectedDemo()"
                             class="uk-margin-small-left">Select</form-button>
                <form-button type="secondary"
                             @click="mode = 'create'"
                             class="uk-margin-small-left">Add an Address</form-button>
            </div>

        </div>
        <div class="create-new uk-animation-fade" v-show="mode == 'create'">

            <a class="title-link uk-margin-bottom-small" @click="mode = 'select'" >
                <span uk-icon="icon: arrow-left" ></span><span>Select from existing addresses</span></a>
            <h5 class="uk-margin-remove-top">Please add your address. You can go back to select an existing address</h5>
            <base-form
                    action="/test/fast"
                    method="post"
                    class="uk-margin-remove uk-animation-slide-bottom-small"
                    :field-wrapper-margin="false"
                    formPath="form/ecommerce-address"
                    :expectedFields="3"
                    @form:submitSuccess="addAddressDemo()"
            ></base-form>
        </div>


    </div>
</template>

<script>
    export default {
        props: {
            modelValue    : {},
            addressList: {
                type: Array,
                default: []
            }
        },
        data: function () {
            return {
                address: {},
                mode: 'select',
            };
        },
        emits: ['update:modelValue', 'shippingData:complete'],
        computed: {
            value: {
                get() {
                    console.log(this.modelValue);
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
        },
        methods: {
            addAddressDemo() {
                this.mode = 'select';
                this.$s.ui.notification('Address added and selected successfully');
                this.$emit('shippingData:complete', null);
            },
            addressSelectedDemo() {
                this.$emit('shippingData:complete', null);
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
